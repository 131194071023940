import React, { memo as Memo, useMemo } from 'react'

//* HOCs
import { withLanguageContext } from 'context'

//* Components
import { Form } from 'antd'
import { Translate } from 'components/common'

//* Helpers
import { formValidation } from 'helpers'

const FormItem = Memo(
	({
		label,
		name,
		errorMessageName,
		type,
		validationType,
		className,
		required = true,
		min,
		max,
		message,
		autocomplete,
		extra,
		extraChild,
		rules = [],
		options = {},
		...props
	}) => {
		const newRules = useMemo(() => {
			let r = [...rules]

			if (type == 'email') {
				r.push({ transform: (value) => value.trim() })
			}

			const typeObj = type ? { type } : ''
			r[0] = { ...r?.[0], message: '', required, ...typeObj }

			const params = r[0]

			if (required || validationType) {
				r[1] = ({ getFieldValue }) => ({
					validator(_, value) {
						const validType = validationType || params.type || 'string'
						let validate

						let settings
						settings = min || typeof min === 'number' ? { ...settings, min: min } : settings
						settings = max ? { ...settings, max: max } : settings

						if (name === 'passwordConfirmation') {
							validate = formValidation(
								Translate,
								{
									pass: getFieldValue('password'),
									c_pass: value,
								},
								'c_password'
							)
						} else {
							validate = formValidation(Translate, value, validType, name + 'IsRequired')
						}

						if (validate === true) {
							return Promise.resolve()
						}

						return Promise.reject(validate)
					},
				})
			}

			return r
		})
		return (
			<Form.Item
				onChange={props.onChange}
				label={label && props.translate(label)}
				hasFeedback
				extra={extra && (extraChild || props.translate(extra))}
				name={name}
				rules={newRules}
				className={className ? className : ''}
				{...options}
			>
				{React.cloneElement(props.children, { autoComplete: autocomplete || 'newpassword' })}
			</Form.Item>
		)
	}
)

export default withLanguageContext(FormItem, ['translate', 'selectedLang'])
