export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
			autoload: false,
		},
		imprint: {
			path: '/imprint',
			slug: 'imprint',
			name: 'imprint',
			autoload: false,
		},
		aboutUs: {
			path: '/about-us',
			slug: 'about-us',
			name: 'aboutUs',
			autoload: false,
		},
		pricing: {
			path: '/pricing',
			slug: 'pricing',
			name: 'pricing',
			autoload: false,
		},
		blog: {
			path: '/blog',
			slug: 'blog',
			name: 'blog',
			autoload: true,
			postType: {
				slug: 'get-posts',
				name: 'posts',
				queryParamKeys: ['catId', 'page'],
			},
		},
		singleBlog: {
			path: '/blog/:slug',
			slug: 'blog',
			name: 'blog',
			singleType: 'singleBlog',
			autoload: true,
		},
		termAndConditions: {
			path: '/term-and-conditions',
			slug: 'terms',
			name: 'termAndConditions',
			autoload: true,
		},
		dataProtection: {
			path: '/data-protection',
			slug: 'data-protection',
			name: 'dataProtection',
			autoload: true,
		},
	},
}
