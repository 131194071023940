import styled from 'styled-components'

const PopupMessageStyle = styled.div`
	--titleMarB: var(--sp5x);
	--wrapPadTB: var(--sp30-5x);
	--circleWidth: 488px;
	--circlePosR: -21%;
	--circlePosB: -14%;
	--pillWidth: 405px;
	--pillPosB: -1%;
	--magnetWidth: 395px;
	--magnetHeight: 72%;
	--magnetPosL: -10%;
	--magnetPosT: -1%;

	padding-top: var(--wrapPadTB);
	padding-bottom: var(--wrapPadTB);

	.title {
		margin-bottom: var(--titleMarB);
	}

	.circle {
		position: absolute;
		right: var(--circlePosR);
		bottom: var(--circlePosB);
		.imageCont {
			width: var(--circleWidth);
			padding-top: 100%;
		}
	}

	.pill {
		position: absolute;
		left: 3%;
		bottom: var(--pillPosB);
		.imageCont {
			width: var(--pillWidth);
			padding-top: 91%;
		}
	}

	.magnet {
		position: absolute;
		left: var(--magnetPosL);
		top: var(--magnetPosT);
		transform: rotate(-120deg);
		.imageCont {
			width: var(--magnetWidth);
			padding-top: var(--magnetHeight);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarB: var(--sp4x);
		--wrapPadTB: var(--sp25x);
		--circleWidth: 360px;
		--circlePosR: -21%;
		--circlePosB: -12%;
		--pillWidth: 299px;
		--pillHeight: 91%;
		--pillPosB: -1%;
		--magnetWidth: 300px;
		--magnetHeight: 72%;
		--magnetPosL: -10%;
		--magnetPosT: -1%;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarB: var(--sp4x);
		--wrapPadTB: var(--sp24x);
		--circleWidth: 323px;
		--circlePosR: -23%;
		--circlePosB: -10%;
		--pillWidth: 268px;
		--pillHeight: 91%;
		--pillPosB: -1%;
		--magnetWidth: 279px;
		--magnetHeight: 72%;
		--magnetPosL: -15%;
		--magnetPosT: -1%;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarB: var(--sp4x);
		--wrapPadTB: var(--sp22x);
		--circleWidth: 323px;
		--circlePosR: -18%;
		--circlePosB: -13%;
		--pillWidth: 268px;
		--pillHeight: 91%;
		--pillPosB: -1%;
		--magnetWidth: 290px;
		--magnetHeight: 72%;
		--magnetPosL: -19%;
		--magnetPosT: -1%;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMarB: var(--sp4x);
		--wrapPadTB: var(--sp20-5x);
		--circleWidth: 296px;
		--circlePosR: -21%;
		--circlePosB: -12%;
		--pillWidth: 245px;
		--pillHeight: 91%;
		--pillPosB: -1%;
		--magnetWidth: 251px;
		--magnetHeight: 72%;
		--magnetPosL: -18%;
		--magnetPosT: -1%;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleMarB: var(--sp4x);
		--wrapPadTB: var(--sp20-5x);
		--circleWidth: 291px;
		--circlePosR: -20%;
		--circlePosB: -13%;
		--pillWidth: 245px;
		--pillHeight: 91%;
		--pillPosB: -1%;
		--magnetWidth: 243px;
		--magnetHeight: 72%;
		--magnetPosL: -17%;
		--magnetPosT: -1%;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMarB: var(--sp3x);
		--wrapPadTB: var(--sp23-5x);
		--circleWidth: 230px;
		--circlePosR: -24%;
		--circlePosB: -6%;
		--pillWidth: 177px;
		--pillHeight: 91%;
		--pillPosB: 11%;
		--magnetWidth: 173px;
		--magnetHeight: 72%;
		--magnetPosL: -19%;
		--magnetPosT: 0%;
	}
`

export default PopupMessageStyle
