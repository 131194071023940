import styled from 'styled-components'

const LogoStyle = styled.div`
	--logoSize: var(--sp30x);
	--logoMargRight: var(--sp25x);

	margin-right: var(--logoMargRight);
	max-width: var(--logoSize);
    width: var(--logoSize);
    
	.Logo {
		display: flex;
		width: 100%;
        z-index:13;

        .imageCont {
            width: 100%;
            padding-top: 25%;
        }

		img {
			width: 100% !important;
			height: auto;
			opacity: 0;
			transition-delay: 5s;
		}

		.LottieWrapp {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--logoSize: var(--sp23x);
		--logoMargRight: var(--sp18x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--logoSize: var(--sp18x);
		--logoMargRight: var(--sp13x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXSMin}) {
		--logoSize: var(--sp16x);
		--logoMargRight: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--logoSize: var(--sp13x);
		--logoMargRight: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--logoSize: var(--sp9x);
		--logoMargRight: var(--sp5x);
	}
`

export default LogoStyle
