import React, { memo as Memo, useCallback, useMemo } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withDataContext } from 'context'

//* Components
import { Text, Button, Translate, LoginBtn, Container, Menu, SocIcon, Title, RequestPopup } from 'components/common'

//* Helpers
import { config } from 'helpers'

//* Styles
import Footer from './style'

const AppFooter = Memo(({ subscribeNewsletter, openPopup, ...props }) => {
    //! Routes from Config
    const routes = useMemo(() => config.routes, [])

    const openPopupFun = useCallback(() => {
        openPopup(<RequestPopup />)
    }, [])

    //! Menu Object

    const menuObj1 = useMemo(
        () => ({
            contClass: `menu-cont 'FlexBoxColumn AlignMiddle'}`,
            itemClass: 'menu-item PolySansNeutralRegular pL whiteDark',
            items: [
                {
                    name: routes.aboutUs.name,
                    path: routes.aboutUs.path,
                },
                {
                    name: routes.pricing.name,
                    path: routes.pricing.path,
                },
            ],
        }),
        [routes]
    )

    const menuObj2 = useMemo(
        () => ({
            contClass: `menu-cont 'FlexBoxColumn AlignMiddle'}`,
            itemClass: 'menu-item PolySansNeutralRegular pL whiteDark',
            items: [
                {
                    name: routes.imprint.name,
                    path: routes.imprint.path,
                },
                {
                    name: routes.blog.name,
                    path: routes.blog.path,
                },
            ],
        }),
        [routes]
    )

    const menuObj3 = useMemo(
        () => ({
            contClass: `menu-cont 'FlexBoxColumn AlignMiddle'}`,
            itemClass: 'menu-item PolySansNeutralRegular pL whiteDark',
            items: [
                {
                    name: routes.dataProtection.name,
                    path: routes.dataProtection.path,
                },
                {
                    name: routes.termAndConditions.name,
                    path: routes.termAndConditions.path,
                },
            ],
        }),
        [routes]
    )

    return (
        <Footer>
            <Container className='footerTop FlexBoxContainer SpaceBetween alignMiddle whiteDark'>
                <div className='footerLeft'>
                    <div className={'footerTitle titleMinMargBot'}>
                        <Text tag={'span'} className={'h2 PolySansNeutralRegular whiteDark'} text={'lookingToHire'} />
                        <Text tag={'span'} className={'h2 PolySansMedianSemiBoldItalic whiteDark'} text={'hire'} />
                        <Text tag={'span'} className={'h2 PolySansNeutralRegular whiteDark'} text={'orTo'} />
                        <Text tag={'span'} className={'h2 PolySansMedianSemiBoldItalic whiteDark'} text={'hired'} />
                    </div>
                    <Text className={'footerText pM PolySansNeutralRegular whiteDark'} text={'footerText'} />

                    <Button onClick={openPopupFun} className={'white'} wrapClassName={'btnContTopMarg'}>
                        <Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} />
                    </Button>
                    <LoginBtn color={'whiteDark'} />
                </div>

                <div className='footerRight'>
                    <div className='footerRightTop FlexBoxContainer'>
                        <Menu type={'nav'} titleClassName={'PolySansMedianSemiBoldItalic pL whiteDark'} title='Company' {...menuObj1} />
                        <Menu type={'nav'} titleClassName={'PolySansMedianSemiBoldItalic pL whiteDark'} title='Resources' {...menuObj2} />
                        <Menu type={'nav'} titleClassName={'PolySansMedianSemiBoldItalic pL whiteDark'} title='Legal' {...menuObj3} />
                    </div>

                    <div className='footerRightBot FlexBoxContainer SpaceBetween'>
                        <div className='footerMenuItem'>
                            <Text tag={'div'} className={'titleMenu PolySansMedianSemiBoldItalic pL whiteDark'} text={'contact'} />
                            <div>
                                <a
                                    className='menu-item PolySansNeutralRegular pL whiteDark'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='mailto:contact@weprofit.global'
                                >
                                    contact@weprofit.global
                                </a>
                                <a
                                    className='menu-item PolySansNeutralRegular pL whiteDark'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='tel:+491796667451'
                                >
                                    + 49 174 204 8888
                                </a>
                                <Text
                                    tag={'div'}
                                    className={'menu-item PolySansNeutralRegular pL whiteDark'}
                                    text={'c/o TechQuartier, Platz der Einheit 2, 60327, Frankfurt am Main'}
                                />
                            </div>
                        </div>
                        <div className='footerMenuItem'>
                            <Text tag={'div'} className={'titleMenu PolySansMedianSemiBoldItalic pL whiteDark'} text={'socials'} />
                            <SocIcon
                                data={{
                                    twitter: 'https://twitter.com/weprofit_global',
                                    facebook: 'https://www.facebook.com/WeProfit.Global/',
                                    instagram: 'https://www.instagram.com/weprofit.global/',
                                    linkedin: 'https://www.linkedin.com/company/weprofitglobal/',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
            <Container className='footerBot FlexBoxContainer SpaceBetween alignMiddle'>
                <Text tag={'div'} className={'FlexBox alignMiddle pS PolySansNeutralRegular uppercase whiteDark'}>
                    <Translate val={'allRightsReserve'} /> © {new Date().getFullYear()}
                </Text>

                <Text tag={'div'} className={'pS PolySansNeutralRegular uppercase whiteDark'}>
                    <Translate val={'madeBy'} />
                    &nbsp;
                    <a className='PolySansNeutralItalic' target='_blank' rel='noopener noreferrer' href='https://conceptstudio.com/'>
                        Concept Studio
                    </a>
                </Text>
            </Container>
        </Footer>
    )
})

export default withLanguageContext(withUIContext(withDataContext(AppFooter, ['subscribeNewsletter']), ['openPopup']), ['translate', 'selectedLang'])
