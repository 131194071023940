import styled from 'styled-components'

const CookiePopupStyle = styled.div`
	display: flex;
	z-index: 10;
	padding: var(--sp3x);
	background: var(--purpleMediumLite);
	position: fixed;
	bottom: 5%;
	width: 38.8%;
	margin: 0 auto;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 10px;
	box-shadow: 0px 6px 15px #5329802f;

	.cookieBtn {
		padding: var(--sp2-5x) var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		width: 42%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		width: 56%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		width: 56%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		width: 70%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		width: 93%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		flex-direction: column;
		width: 90%;
		.cookieBtn {
			margin-top: var(--sp2x);
		}
	}
`

export default CookiePopupStyle
