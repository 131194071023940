import React, { memo as Memo } from 'react'

//* Components
import { CustomLink, Icon, Translate } from 'components/common'

//* Style
import LoginStyle from './style'

const Login = Memo((props) => {
	return (
		<LoginStyle className={props.color ? props.color : 'purple'}>
			<a
				target='_blank'
				href={'https://app.weprofit.global/login/'}
				className={`FlexBox AlignMiddle Login PolySansMedianRegular pM uppercase ${props.color ? props.color : 'purple'}`}
			>
				<Icon className={`icon-openAccountLogin ${props.color ? props.color : 'purple'}`} />
				<span>
					<Translate val={'login'} />
				</span>
			</a>
		</LoginStyle>
	)
})

export default Login
