import React, { memo as Memo, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

//* HOC's
import { withUIContext } from 'context'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const AnimationWrapp = Memo(({ state, startAnimeArr, startPoint, ...props }) => {
	useEffect(() => {
		let genArr = []

		if (!props.preloader && state?.length > 0 && state !== null) {
			state.forEach((el, i) => {
				if (el) {
					ScrollTrigger.create({
						trigger: el,
						// markers:true,
						start: startPoint || 'top bottom',
						onEnter: () => {
							genArr.push(true)

							if (genArr.length <= state.length) {
								startAnimeArr(genArr)
							}
						},
						// onLeave: () => {
						// 	if (i === 0) genArr = []
						// 	genArr.push(false)

						// 	if (genArr.length === state.length) startAnimeArr(genArr)
						// },
						// onLeaveBack: () => {
						// 	if (i === state.length - 1) genArr = []
						// 	genArr.push(false)

						// 	if (genArr.length === state.length) startAnimeArr(genArr)
						// },
					})
				}
			})
		}
	}, [state, props.preloader])

	useEffect(() => {
		return () => {
			ScrollTrigger.getAll().forEach((t) => t.kill())
		}
	}, [])

	return <>{props.children}</>
})

export default withUIContext(AnimationWrapp, ['preloader'])
