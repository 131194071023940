import styled from 'styled-components'

const PopupStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--closeIconSize: var(--sp3-5x);
	--closeSizePos: var(--sp4x);
	--popupPadding: var(--sp13x);
	--popupContentMaxWidth: 35.2%;

	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 111;

	.popup-content-container {
		max-width: var(--popupContentMaxWidth);
		width: 100%;
		height: 100%;
		pointer-events: all;
		overflow: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		.popup-background {
			background-color: rgb(37 37 37 / 47%);
			pointer-events: all;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.popup-content-wrap {
			width: 100%;
			background: #f0f0f0 0% 0% no-repeat padding-box;
			border-radius: 6px;
			opacity: 1;
			padding: var(--popupPadding);
			overflow: hidden;

			.close {
				position: absolute;
				top: var(--closeSizePos);
				right: var(--closeSizePos);
				width: var(--closeIconSize);
				height: var(--closeIconSize);
				color: #9561cc;
				i {
					font-size: var(--closeIconSize);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--popupContentMaxWidth: 34.6%;
		--closeIconSize: var(--sp3x);
		--closeSizePos: var(--sp3x);
		--popupPadding: var(--sp8x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--popupContentMaxWidth: 41.7%;
		--closeIconSize: var(--sp3x);
		--closeSizePos: var(--sp3x);
		--popupPadding: var(--sp8x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--popupContentMaxWidth: 46.9%;
		--closeIconSize: var(--sp3x);
		--closeSizePos: var(--sp3x);
		--popupPadding: var(--sp8x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--popupContentMaxWidth: 52.8%;
		--closeIconSize: var(--sp3x);
		--closeSizePos: var(--sp3x);
		--popupPadding: var(--sp7x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--popupContentMaxWidth: 70.4%;
		--closeIconSize: var(--sp2-5x);
		--closeSizePos: var(--sp2-5x);
		--popupPadding: var(--sp7x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--popupContentMaxWidth: 94.3%;
		--closeIconSize: var(--sp2-5x);
		--closeSizePos: var(--sp1-5x);
		--popupPadding: var(--sp6x);

		.popup-content-container {
			.popup-content-wrap {
				padding: var(--popupPadding) var(--sp1-5x);
			}
		}
	}
`
export default PopupStyle
