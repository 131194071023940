import React, { memo as Memo } from 'react'

//* Components
import { LottieAnimation, CustomLink, Image } from 'components/common'

//* Styles
import LogoStyle from './style'
import logoLottie from 'lottieAnimation/global/logo.json'

const Logo = Memo((props) => {
    return (
        <LogoStyle>
            <CustomLink
                url={'/'}
                className={`Logo relativeWrap`}
                content={
                    <>
                        <LottieAnimation animData={logoLottie} isPlay={!props.start} autoplay={false} loop={false} />
                        <Image layout='fill' src={'/images/svg/Logo.svg'} alt='Logo' />
                    </>
                }
            />
        </LogoStyle>
    )
})
export default Logo
