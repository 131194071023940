import React, { memo as Memo } from 'react'

//* Components
import { Container, Image, Title } from 'components/common'

//* Style
import OurPartnersStyle from './style'

const OurPartners = Memo(({ data, marginBottom, ...props }) => {
	return data ? (
		<OurPartnersStyle className='overflowHidden'>
			<Container className={`${marginBottom ? 'containerPaddBottom' : ''}`}>
				<Title className={'titleWrap purple'} text={['Known', 'from']} />

				<div className='imageWrapp FlexBoxContainer SpaceBetween'>
					{data.map((item, ind) => {
						return (
							<div key={ind}>
								<Image src={item} alt='partners_image' />
							</div>
						)
					})}
				</div>
			</Container>
		</OurPartnersStyle>
	) : (
		''
	)
})

export default OurPartners
