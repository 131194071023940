import styled from 'styled-components'

const LottieStyle = styled.div.attrs('')`
	&.LottieWrapp {
		position: relative;
		svg {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
	}
`
export default LottieStyle
