import axios from 'axios'
import Cookie from 'js-cookie'

class Api {
	requester = axios.create()

	constructor() {
		this.requester.interceptors.request.use((request) => {
			request.baseURL = process.env.REACT_APP_API_SERVER
			request.headers.common['X-Requested-With'] = 'XMLHttpRequest'

			if (Cookie.get('access_token')) {
				request.headers.Authorization = `Bearer ${Cookie.get('access_token')}`
			}

			if (Cookie.get('lang')) {
				request.headers['Content-Language'] = Cookie.get('lang')
			}

			return request
		})

		this.requester.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				return Promise.reject(error)
			}
		)

		this.upload = this.upload.bind(this)
	}

	async get(resource, lang, params = {}, access_token = null) {
		params = new Map(Object.entries(params))

		const options = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Language': lang,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}

		access_token && (options.headers.Authorization = `Bearer ${access_token}`)

		const response = await this.request(`${resource}?${Api.serializeParams(params)}`, options)

		return response.data
	}

	async post(resource, params = {}, lang = undefined, contentType, access_token) {
		const options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Language': lang,
				'Content-Type': contentType || 'application/json',
			},
			data: params,
		}

		access_token && (options.headers.Authorization = `Bearer ${access_token}`)

		lang && (options.headers['Content-Language'] = lang)

		const response = await this.request(resource, options)

		return response.data
	}

	async upload(url, file) {
		const options = {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		}

		const formData = new FormData()
		formData.append('file', file)

		try {
			const response = await this.requester.post(`${process.env.REACT_APP_API_SERVER}${url}`, formData, options)
			return response.data
		} catch (e) {
			throw new Error(e)
		}
	}

	async put(resource, params = {}, lang, contentType, access_token) {
		if (contentType === 'multipart/form-data') {
			params.append('_method', 'PUT')
		}

		const options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Language': lang,
				'Content-Type': contentType || 'application/json',
			},
			data: params,
		}

		access_token && (options.headers.Authorization = `Bearer ${access_token}`)

		lang && (options.headers['Content-Language'] = lang)

		const response = await this.request(resource, options)

		return response.data
	}

	async delete(resource, params = {}) {
		const options = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			data: { ...params },
		}

		const response = await this.request(resource, options)

		return response.data
	}

	async request(url, options) {
		return this.requester(url, options)
	}

	static serializeParams(params) {
		const array = []

		params.forEach((value, key) => {
			array.push(`${key}=${encodeURIComponent(value)}`)
		})

		return array.join('&')
	}
}

export default new Api()
