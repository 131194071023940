import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

// Style
import CookiePopupStyle from './style'

// Components
import { Text, Button } from 'components/common'

const CookiePopup = () => {
	const [isAccepted, setIsAccepted] = useState(false)

	useEffect(() => {
		setIsAccepted(Cookies.get('accepted') ? true : false)
	}, [])

	return !isAccepted ? (
		<CookiePopupStyle className='FlexBox AlignMiddle SpaceBetween AlignCenter'>
			<Text
				tag={'p'}
				className={'pS purple PolySansNeutralRegular'}
				text={'We use necessary cookies only. No personalized cookies are processed by WeProfit.'}
			/>

			<Button
				onClick={() => {
					setIsAccepted(true)
					Cookies.set('accepted', true)
				}}
				className='PolySansNeutralRegular cookieBtn'
			>
				<span className='white pS'>Got it</span>
			</Button>
		</CookiePopupStyle>
	) : null
}

export default CookiePopup
