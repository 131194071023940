import App from 'next/app'
import React from 'react'

//* Providers
import { UIProvider, DataProvider, LanguageProvider } from 'context'
import { ThemeProvider } from 'styled-components'

//* Components
import { AppHeader, AppFooter, Preloader, CookiePopup, Popup } from 'components/common'

//* Helpers
import { getData, getPageParams, config } from 'helpers'

//* Styles
import '../styles/icons/style.css'
import 'swiper/swiper-bundle.min.css'
import theme from '../styles/theme'
import Variables from 'styles/variables'
import Typography from 'styles/typography'
import HelperClass from '../styles/helperClass'

class MyApp extends App {
	static async getInitialProps({ Component, ctx, router, ...props }) {
		//! Checking If First Request or Not
		if (!process.browser) {
			//! Default States
			const newState = {
				pages: {},
				global: {},
				singleItem: {},
			}
			let userData = {
				isLoggedIn: false,
				privatePages: {},
			}

			//! Getting Page Data
			const data = await getData(router.locale, newState, ctx, true)

			return {
				pageProps: {
					...data,
					browser: false,
				},
				userProps: userData.newState,
			}
		} else {
			return {
				pageProps: {
					pageParams: getPageParams(router.locale, ctx),
					browser: true,
				},
			}
		}
	}

	render() {
		const { Component, pageProps, router } = this.props

		return (
			<LanguageProvider>
				<UIProvider>
					<DataProvider newData={pageProps}>
						<ThemeProvider theme={theme}>
							<Preloader />
							<Variables />
							<Typography />
							<HelperClass />
							{router.pathname !== '/404' && <AppHeader />}
							<CookiePopup />
							<Popup />
							<div className='minHeight'>
								<Component {...pageProps} config={config.routes} selectedLang={router.locale} />
							</div>
							{router.pathname !== '/404' && <AppFooter />}
						</ThemeProvider>
					</DataProvider>
				</UIProvider>
			</LanguageProvider>
		)
	}
}

export default MyApp
