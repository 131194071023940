import React, { memo as Memo, useMemo } from 'react'
//* Components
import { Translate } from 'components/common'

const Title = Memo((props) => {

    //! Button Type
    const text = useMemo(() => {

        return (
            <div className={`${props.className ? props.className : "purple"}`}>
                {
                    Object.values(props.text).map((item, key) => {
                        if (key % 2 === 0) {
                            return (
                                <span key={key} className={`PolySansNeutralRegular ${props.fontSize ? props.fontSize : "h2"}`} >
                                    <Translate val={item} />&nbsp;
                                </span>
                            )
                        }
                        else {
                            return (
                                <span key={key} className={`${props.type == "btn" ? "PolySansSlimLightItalic" : "PolySansMedianSemiBoldItalic"} ${props.fontSize ? props.fontSize : "h2"}`} >
                                    <Translate val={item} />&nbsp;
                                </span>
                            )
                        }
                    })
                }
            </div>
        )
    }, [props])


    return (
        <div className={`Title ${props.wrapClassName ? props.wrapClassName : ""}`}>
            {text}
        </div>
    )
})

export default Title