import styled from 'styled-components'

const RequestPopupStyle = styled.div`
	--titleMB: var(--sp6x);
	--btnPadTb: var(--sp4x);
	--btnPadLR: var(--sp5x);
	--inputHeight: var(--sp8x);
	--form50Width: 47.7%;
	--inputMarB: var(--sp4x);
	--lastInputMarB: var(--sp5x);
	--checkboxMarB: var(--sp7x);
	--inpPadL: var(--sp2-5x);
	--checkboxIconWH: var(--sp3x);
	--checkPosT: 10%;
	--checkPosL: 35%;
	--errSize: var(--sp1-75x);

	.Title {
		margin-bottom: var(--titleMB);
	}

	.formWrapper {
		.ant-row {
			position: relative;

			//! Input Error
			.ant-form-item-explain {
				position: absolute;
				top: 100%;
				transform: translateY(calc(var(--spSize) / 2));
				line-height: 1;
				min-height: auto;
				color: var(--errColor);
				font-family: PolySansNeutralRegular;
				font-size: var(--errSize);
			}
		}

		//! Error
		.ant-form-item-has-error {
			position: relative;

			.ant-input {
				border: 1px solid var(--errColor) !important;
				&::placeholder {
					color: var(--errColor) !important;
				}
			}
		}
		//!END

		.ant-input {
			&::placeholder {
				color: var(--purple) !important;
				font-size: var(--pM);
				font-family: PolySansNeutralRegular;
			}
		}

		.row {
			margin-bottom: var(--inputMarB);
			.ant-form-item-control-input {
				min-height: auto;
				.ant-input {
					width: 100%;
					height: var(--inputHeight);
					background: var(--white) 0% 0% no-repeat padding-box;
					border: 1px solid var(--purpleLite);
					border-radius: var(--borderSm);
					opacity: 1;
					padding: 0px var(--inpPadL);
					color: var(--purpleLite) !important;
					font-family: PolySansNeutralRegular;
					font-size: var(--pM);
				}

                .ant-input-suffix {
                    display: none !important;
                }
			}
		}

		.lastInput {
			margin-bottom: var(--lastInputMarB);
			.ant-form-item {
				width: var(--form50Width);
			}
		}

		.checkbox {
			margin-bottom: var(--checkboxMarB);
			.ant-checkbox-wrapper {
				display: flex;
				align-items: flex-start;
				cursor: pointer;

				.ant-checkbox {
					.ant-checkbox-input {
						border: 1px solid var(--purpleLite);
						width: var(--checkboxIconWH);
						height: var(--checkboxIconWH);
						margin: 0px var(--spSize) 0px 0px;
					}
					&.ant-checkbox-checked {
						.ant-checkbox-input {
							position: relative;
							background-color: var(--purpleLite);
							&:after {
								content: '';
								position: absolute;
								top: var(--checkPosT);
								left: var(--checkPosL);
								width: 5px;
								height: 10px;
								border: 1px solid #fff;
								border-width: 0 3px 3px 0;
								transform: rotate(45deg);
							}
						}
					}
				}
			}
		}

		.formBtnWrap {
			.ant-btn {
				-webkit-appearance: none;
				cursor: pointer;
				display: inline-block;
				z-index: 1;
				padding: var(--btnPadTb) var(--btnPadLR);
				border: 0;
				position: relative;
				line-height: var(--lineHeightXS);
				font-size: var(--pM);
				font-family: PolySansNeutralRegular;
				color: #efecec;
				text-align: center;
				height: auto;
				background: transparent;

				&:disabled {
					background-color: var(--greyLight);
					border-color: var(--greyLight);
					pointer-events: none;
					.backColor {
						background-color: var(--greyLight);
					}
				}

				.backColor {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: var(--purple);
					border-radius: var(--borderSm);
					z-index: -3;
					transform: scale(1);
					transition: all var(--trTime2) ease;
					&:after,
					&:before {
						content: '';
						border-radius: var(--borderSm);
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						top: 100%;
					}
					&:before {
						z-index: -2;
						background-color: var(--purpleLite);
						opacity: 0.6;
						transition: all var(--trTime3) ease;
					}
					&:after {
						z-index: -1;
						background-color: var(--purpleLite);
						transition: all var(--trTime) ease;
					}
				}

				&:hover {
					color: var(--whiteDark);
					.backColor {
						transform: scale(1.05);
						&:after,
						&:before {
							top: 0;
						}
						&:before {
							transition: all var(--trTime) ease;
						}
						&:after {
							transition: all var(--trTime3) ease;
						}
					}
				}
			}
		}
		.ant-form-item-children-icon {
			display: none;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--titleMB: var(--sp4x);
		--btnPadTb: var(--sp3x);
		--btnPadLR: var(--sp5x);
		--inputHeight: var(--sp6-5x);
		--form50Width: 47.7%;
		--inputMarB: var(--sp3x);
		--lastInputMarB: var(--sp4x);
		--checkboxMarB: var(--sp6x);
		--inpPadL: var(--sp2x);
		--checkboxIconWH: var(--sp2-5x);
		--checkPosT: 7%;
		--checkPosL: 27%;
		--errSize: var(--sp1-5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--titleMB: var(--sp3x);
		--btnPadTb: var(--sp2-5x);
		--btnPadLR: var(--sp5x);
		--inputHeight: var(--sp6x);
		--form50Width: 48%;
		--inputMarB: var(--sp2-5x);
		--lastInputMarB: var(--sp3x);
		--checkboxMarB: var(--sp4x);
		--inpPadL: var(--sp2x);
		--checkboxIconWH: var(--sp2-5x);
		--checkPosT: 3%;
		--checkPosL: 25%;
		--errSize: var(--sp1-5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMB: var(--sp3x);
		--btnPadTb: var(--sp2-5x);
		--btnPadLR: var(--sp5x);
		--inputHeight: var(--sp6x);
		--form50Width: 48%;
		--inputMarB: var(--sp3x);
		--lastInputMarB: var(--sp3x);
		--checkboxMarB: var(--sp3x);
		--inpPadL: var(--sp2x);
		--checkboxIconWH: var(--sp3x);
		--checkPosT: 7%;
		--checkPosL: 33%;
		--errSize: var(--sp1-5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		--titleMB: var(--sp3x);
		--btnPadTb: var(--sp2x);
		--btnPadLR: var(--sp4x);
		--inputHeight: var(--sp6x);
		--form50Width: 47.6%;
		--inputMarB: var(--sp3x);
		--lastInputMarB: var(--sp3x);
		--checkboxMarB: var(--sp3x);
		--inpPadL: var(--sp2x);
		--checkboxIconWH: var(--sp2-5x);
		--checkPosT: 3%;
		--checkPosL: 28%;
		--errSize: var(--sp1-5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--titleMB: var(--sp3x);
		--btnPadTb: var(--sp2x);
		--btnPadLR: var(--sp4x);
		--inputHeight: var(--sp6x);
		--form50Width: 47.6%;
		--inputMarB: var(--sp3x);
		--lastInputMarB: var(--sp3x);
		--checkboxMarB: var(--sp3x);
		--inpPadL: var(--sp2x);
		--checkboxIconWH: var(--sp2-5x);
		--checkPosT: 3%;
		--checkPosL: 30%;
		--errSize: var(--sp1-5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMB: var(--sp2-5x);
		--btnPadTb: var(--sp1-5x);
		--btnPadLR: var(--sp3x);
		--inputHeight: var(--sp5x);
		--form50Width: 45.6%;
		--inputMarB: var(--sp3x);
		--lastInputMarB: var(--sp3x);
		--checkboxMarB: var(--sp2x);
		--inpPadL: var(--sp2x);
		--checkboxIconWH: var(--sp2-5x);
		--checkPosT: 7%;
		--checkPosL: 31%;
		--errSize: var(--sp1-5x);
	}
`

export default RequestPopupStyle
