import React, { memo as Memo ,useEffect,useState} from 'react'
import { withUIContext } from 'context'

import { LottieAnimation } from 'components/common'

import preloaderAnimation from 'lottieAnimation/global/preloader.json'

//* Style's
import LoaderStyle from './style'

const Loader = Memo((props) => {

	const [show , setShow] = useState(true)

	useEffect(()=>{
		const timer = setTimeout(()=>{
			setShow(false)
		},2000);
		return () => clearTimeout(timer);
	},[])

	return props.preloader ? (
		<LoaderStyle style={{
			position: 'fixed',
			top: '0',
			width: '100vw',
			height: '100vh',
			left: '0',
			zIndex: '112',
			overflow: 'hidden'
		}}
			className={`FlexBox AlignMiddle AlignCenter ${show ? "" : "active"}`}>
			<LottieAnimation animData={preloaderAnimation} isPlay={true} autoplay={true} loop={false} />
		</LoaderStyle >
		)
		:
		null

})

export default withUIContext(Loader, ['preloader'])
