import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import Image from 'next/image'
import ImageStyle from './style'

const CustomImage = forwardRef((props, ref) => {
    const imgRef = useRef()

    useImperativeHandle(ref, () => [imgRef.current], [])
    return (
        <ImageStyle className={`imageCont ${props.className ? props.className : ''} `} layout='fill' ref={imgRef}>
            <Image layout='fill' unoptimized={props.src.includes('.svg')} src={props.src} alt={props.alt || 'image'} className='image' />
        </ImageStyle>
    )
})

export default CustomImage
