import styled from 'styled-components'

const PreloaderStyle = styled.section`
	background-color: var(--purple);

	.LottieWrapp {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--purple);
	}
`
export default PreloaderStyle
