import React, { memo as Memo, useCallback, useState } from 'react'
import { withLanguageContext, withDataContext } from 'context'

//* Components
import { Text, Title, FormItem, FormContainer, FormInput, PopupMessage } from 'components/common'

//* Antd
import { Button, Checkbox } from 'antd'

//* Styles
import RequestPopupStyle from './style'

const RequestPopup = Memo(({ translate, ...props }) => {
	const [accept, setAccept] = useState(false)
	const [message, setMessage] = useState(false)

	const handleAcceptChange = useCallback((e) => {
		setAccept(e.target.checked)
	}, [])

	const handleOnFinish = useCallback((values) => {
		return props.requestPopup(values).then(() => {
			setMessage(true)
		})
	}, [])

	return (
		<RequestPopupStyle>
			{message ? (
				<PopupMessage />
			) : (
				<>
					<Title text={['requestAn', 'onboarding']} fontSize={'h5'} />
					<FormContainer className='formWrapper' onFinish={handleOnFinish}>
						<div className='row'>
							<FormItem name={'name'}>
								<FormInput className='inputField' placeholder={translate('name') + '*'} />
							</FormItem>
						</div>
						<div className='row'>
							<FormItem name={'surname'}>
								<FormInput className='inputField' placeholder={translate('surname') + '*'} />
							</FormItem>
						</div>
						<div className='row'>
							<FormItem name={'email'} validationType='email' errorMessageName='email'>
								<FormInput className='inputField' placeholder={translate('businessEmail') + '*'} />
							</FormItem>
						</div>
						<div className='FlexBoxContainer SpaceBetween row lastInput'>
							<FormItem name={'company'} required={false}>
								<FormInput className='inputField' placeholder={translate('companyName')} />
							</FormItem>
							<FormItem name={'phone'} required={false}>
								<FormInput className='inputField' type='number' placeholder={translate('phone')} />
							</FormItem>
						</div>
						<FormItem className='checkbox' name='checkbox' required={false} options={{ valuePropName: 'checked' }}>
							<Checkbox onChange={handleAcceptChange}>
								<Text tag={'span'} className={'pS greyUltraDark PolySansNeutralRegular'} text={'checkboxMessage'} />
							</Checkbox>
						</FormItem>
						<div className='formBtnWrap FlexBox JustifyEnd'>
							<Button htmlType={'Submit'} className={`${!accept ? 'disabled' : ''}`} disabled={!accept}>
								{translate('submit')}
								<span className='backColor' />
							</Button>
						</div>
					</FormContainer>
				</>
			)}
		</RequestPopupStyle>
	)
})

export default withLanguageContext(withDataContext(RequestPopup, ['requestPopup']), ['selectedLang', 'translate'])
