import styled from 'styled-components'

const ShareStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--shareLinkkSize: 30px;
	--shareLinkkRight: var(--sp3x);

	.socIconItem {
		font-size: var(--shareLinkkSize);
		margin-right: var(--shareLinkkRight);
        padding:5px;
        background-color:var(--whiteDark);
        border-radius:var(--borderSm);
        i{
            color:currentColor;
        }
		&:last-child {
			margin-right: 0;
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) {
		--shareLinkkSize: 22px;
		--shareLinkkRight: var(--sp2x);
	}
`

export default ShareStyle
