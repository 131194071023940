import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }

    body {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        color: var(--black);
        font-family: PolySansNeutralRegular;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        position: relative;
        background-color:var(--backgroundGrey);

        main {
            min-height: 100vh;
            z-index: 1;
            position: relative;
        }

        .minHeight {
            min-height: 100vh;
        }

        #root {
            overflow-x: hidden;
        }

        &::-webkit-scrollbar {
            display: none;
        }


        --titleMinMarg: var(--sp4x);
        --titleMaxMarg: var(--sp5x);
        --btnTopMarg: var(--sp13x);

        .titleMinMargBot {
            margin-bottom: var(--titleMinMarg);
        }

        .titleMaxMargBot {
            margin-bottom: var(--titleMaxMarg);
        }

        @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {
            --titleMinMarg: var(--sp3x);	
            --titleMaxMarg: var(--sp4x);
            --btnTopMarg: var(--sp9x);
        }

        @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
            --titleMinMarg: var(--sp3x);
            --titleMaxMarg: var(--sp3x);
            --btnTopMarg: var(--sp7x);
        }

        @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
            --btnTopMarg: var(--sp7x);
        }

        @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
            --titleMinMarg: var(--sp2x);
            --titleMaxMarg: var(--sp3x);
            --btnTopMarg: var(--sp6x);
        }

        @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
            --titleMaxMarg: var(--sp2x);
            --btnTopMarg: var(--sp5x);
        }
    }

    .modal-open {
        overflow-y: hidden;

        * {
            touch-action: none;
            -ms-touch-action: none;
        }
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    a {
        text-decoration: none;
        color: currentColor;
    }

    // flex box

    .FlexBoxContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .FlexBoxColumn {
        display: flex;
        flex-direction: column;
    }

    .FlexBox {
        display: flex;
    }

    .AlignMiddle {
        align-items: center;
    }

    .AlignCenter {
        justify-content: center;
    }

    .JustifyEnd {
        justify-content: flex-end;
    }

    .JustifyStart {
        justify-content: flex-start;
    }
    
    .AlignEnd{
        align-items: flex-end;
    }

    .SpaceBetween {
        justify-content: space-between;
    }

    //!Colors
    .white {
        color:var(--white);
    }
    .whiteDark {
        color:var(--whiteDark);
    }
    .purple {
        color:var(--purple);
    }
    .purpleLite {
        color:var(--purpleLite);
    }
    .purpleMediumLite {
        color:var(--purpleMediumLite);
    }
    .purpleUltraLite {
        color:var(--purpleUltraLite);
    }
    .grey {
        color:var(--grey);
    }
    .greyUltraDark {
        color:var(--greyUltraDark);
    }
    .greyLight {
        color:var(--greyLight);
    }
    .green {
        color:var(--green);
    }
    .greenLight {
        color:var(--greenLight);
    }
    .orange {
        color:var(--orange);
    }
    .orangeDeep {
        color:var(--orangeDeep);
    }
    

    //errColor
    .error {
        color:var(--errColor);
    }

    //relative

    .relativeWrap{
        position: relative;
    }

    .overflowHidden{
        overflow:hidden;
    }

    .cursorPointer{
        cursor:pointer;
    }

    .textAlignCenter {
        text-align:center;
    }
`

export default HelperClass
