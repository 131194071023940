import React, { useMemo, forwardRef } from 'react'
import { CustomLink } from 'components/common'

//* HOCs
import { withLanguageContext } from 'context'
import withUIContext from 'context/consumerHOC/UIConsumer'

//style
import ButtonStyle from './style'

const Button = forwardRef((props, ref) => {
	//! Button Type
	const btn = useMemo(() => {
		if (props.link) {
			return (
				<ButtonStyle className={`btn-cont FlexBox AlignMiddle ${props.wrapClassName ? props.wrapClassName : ''}`} ref={ref}>
					<CustomLink url={props.link} className={`btn pM PolySansNeutralRegular ${props.className || ''}`}>
						{props.children || props.translate(props.text)}
						<span className='backColor' />
					</CustomLink>
				</ButtonStyle>
			)
		} else {
			return (
				<ButtonStyle className={`btn-cont FlexBox AlignMiddle ${props.wrapClassName ? props.wrapClassName : ''}`}>
					<button
						type={props.type || 'button'}
						title={props.title || ''}
						className={`btn pM PolySansNeutralRegular ${props.className || ''}`}
						ref={ref}
						aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
						onClick={props.onClick}
					>
						{props.children || props.translate(props.text)}
						<span className='backColor' />
					</button>
				</ButtonStyle>
			)
		}
	}, [props])

	return btn
})

export default withUIContext(withLanguageContext(Button, ['translate', 'selectedLang']))
