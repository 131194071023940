import styled from 'styled-components'

const LoginStyle = styled.div`
	--margRight: var(--sp3x);

	.Login {
		i {
			margin-right: var(--margRight);
		}
		span{
			line-height: 1;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--margRight: var(--sp2x);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--margRight: var(--sp2x);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXSMin}) {
		--margRight: var(--sp1-5x);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSizeMin}) {
		--margRight: var(--sp1-5x);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--margRight: var(--spSize);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--margRight: var(--spSize);
	}
`

export default LoginStyle
