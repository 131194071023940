import styled from 'styled-components'

const ButtonStyle = styled.div`
	--maxWidthBntMid: 100%;
	--paddingBtnTop: var(--sp4x);
	--paddingBtnLeft: var(--sp6x);

	&.btnContTopMarg {
		margin-top: var(--btnTopMarg);
	}

	&.btnMaxWidth {
		width: 100%;
		.btn {
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}

	.btn {
		-webkit-appearance: none;
		cursor: pointer;
		display: inline-block;
		z-index: 1;
		padding: var(--paddingBtnTop) var(--paddingBtnLeft);
		border: 0;
		position: relative;
		border-radius: var(--spSize);

		&.white {
			color: var(--purple);
			.backColor {
				background-color: var(--whiteDark);

				&:before {
					background-color: var(--purpleLite);
				}
				&:after {
					background-color: var(--purpleLite);
				}
			}
		}

		.backColor {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: var(--purple);
			border-radius: var(--borderSm);
			z-index: -3;
			transform: scale(1);
			transition: all var(--trTime2) ease;
			&:after,
			&:before {
				content: '';
				border-radius: var(--borderSm);
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 100%;
			}
			&:before {
				z-index: -2;
				background-color: var(--purpleLite);
				opacity: 0.6;
				transition: all var(--trTime3) ease;
			}
			&:after {
				z-index: -1;
				background-color: var(--purpleLite);
				transition: all var(--trTime) ease;
			}
		}

		&:hover {
			.backColor {
				transform: scale(1.05);
				&:after,
				&:before {
					top: 0;
				}
				&:before {
					transition: all var(--trTime) ease;
				}
				&:after {
					transition: all var(--trTime3) ease;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--paddingBtnTop: var(--sp2-5x);
		--paddingBtnLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--paddingBtnTop: var(--sp2x);
		--paddingBtnLeft: var(--sp4x);

		.btn {
			&:hover {
				.backColor {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: var(--purple);
					transform: scale(1);

					&:after,
					&:before {
						top: unset;
					}
				}
			}

			&.white {
				&:hover {
					color: var(--purple);
					.backColor {
						background-color: var(--whiteDark);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--paddingBtnTop: var(--sp2x);
		--paddingBtnLeft: var(--sp3x);

		.btn {
			&:hover {
				.backColor {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: var(--purple);
					transform: scale(1);

					&:after,
					&:before {
						top: unset;
					}
				}
			}

			&.white {
				&:hover {
					color: var(--purple);
					.backColor {
						background-color: var(--whiteDark);
					}
				}
			}
		}
	}
`

export default ButtonStyle
