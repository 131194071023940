import React from 'react'
import _ from 'lodash'

export const isMobile = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function removePrefix(str) {
	return str.replace('mm_', '')
}

export const queryStringToObject = (url) => {
	return _.chain(url).replace('?', '').split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value()
}

export const asyncFunctionDecorator = (asyncFunc) => {
	return asyncFunc().catch((err) => {
		return Promise.reject(err.response)
	})
}

export const objectToQueryString = (obj) => {
	if (obj) {
		const results = []
		_.forOwn(obj, (value, key) => {
			if (Array.isArray(value)) {
				_.forOwn(value, (value) => {
					results.push(`${key}=${value}`)
				})
			} else {
				results.push(`${key}=${value}`)
			}
		})
		return results.join('&')
	}
	return ''
}

export const routerToPathAndQuery = (router, key, val, multiple, clearKeyItems) => {
	let newVal = val?.toString()

	if (multiple) {
		let paramsArr = getParamsByKey(router, key)
		if (paramsArr) {
			paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal]
			newVal = paramsArr.join(',')
		}
	}

	const queryObj = { ...router.query, [key]: newVal }
	delete queryObj['params']

	if (clearKeyItems) {
		delete queryObj[clearKeyItems]
	}

	if (router.query[key] === newVal || !newVal) {
		delete queryObj[key]
	}

	const paramsPath = router.query.params && router.query.params.join('/')
	let pathname = router.pathname
	pathname = pathname.replace('[[...params]]', paramsPath || '')

	return { pathname, query: queryObj }
}

export const getParamsByKey = (router, key) => {
	return router.query[key]?.split(',')
}

export const getPageNumbers = ({ currentPage, pageSize, total, pageNumbersToShow = 3 }) => {
	const lastPageNumber = Math.ceil(total / pageSize)
	const currentPageNumber = currentPage <= lastPageNumber ? currentPage : lastPageNumber
	const maxPagesBeforeCurrentPage = Math.floor(pageNumbersToShow / 2)
	const maxPagesAfterCurrentPage = Math.ceil(pageNumbersToShow / 2) - 1
	let startPage = 1
	let endPage = lastPageNumber

	if (lastPageNumber <= 1) {
		return [] // Don't show numbers if there's only 1 page
	}

	if (currentPageNumber <= maxPagesBeforeCurrentPage) {
		// near the start
		startPage = 1
		endPage = pageNumbersToShow
	} else if (currentPageNumber + maxPagesAfterCurrentPage >= lastPageNumber) {
		// near the end
		startPage = lastPageNumber - pageNumbersToShow + 1
	} else {
		// somewhere in the middle
		startPage = currentPageNumber - maxPagesBeforeCurrentPage
		endPage = currentPageNumber + maxPagesAfterCurrentPage
	}

	let pageNumbers = Array.from(Array(endPage + 1 - startPage).keys())
		.map((pageNumber) => startPage + pageNumber)
		.filter((pageNumber) => pageNumber <= lastPageNumber && pageNumber > 0)

	if (pageNumbers[0] > 1) {
		if (pageNumbers[0] <= 2) {
			pageNumbers = [1, ...pageNumbers]
		} else {
			const ellipsis = pageNumbers[0] > 3 ? '...' : 2
			pageNumbers = [1, ellipsis, ...pageNumbers]
		}
	}

	if (pageNumbers[pageNumbers.length - 1] !== lastPageNumber) {
		if (pageNumbers[pageNumbers.length - 1] === lastPageNumber - 1) {
			pageNumbers = [...pageNumbers, lastPageNumber]
		} else {
			const ellipsis = pageNumbers[pageNumbers.length - 1] < lastPageNumber - 2 ? '...' : lastPageNumber - 1
			pageNumbers = [...pageNumbers, ellipsis, lastPageNumber]
		}
	}

	return pageNumbers
}
