import React, { memo as Memo } from 'react'

import { withLanguageContext } from 'context'

//* Components
import { Text, Image } from 'components/common'

//* Styles
import PopupMessageStyle from './style'

const PopupMessage = Memo(({ ...props }) => {
	return (
		<PopupMessageStyle>
			<Text tag={'h2'} className={'h2 purple PolySansMedianSemiBoldItalic title'} text={`${props.translate('great')}!`} />
			<Text tag={'p'} className={'pM purple PolySansNeutralRegular'} text={`${props.translate('greatMessage')}!`} />
			<div className='circle'>
				<Image src='/images/popupMessage/circle.svg' alt={'Circle'} />
			</div>
			<div className='magnet'>
				<Image src='/images/popupMessage/magnet.svg' alt={'Magnet'} />
			</div>
			<div className='pill'>
				<Image src='/images/popupMessage/pill.svg' alt={'Pill'} />
			</div>
		</PopupMessageStyle>
	)
})

export default withLanguageContext(PopupMessage, ['selectedLang', 'translate'])
