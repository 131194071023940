import React, { Component, createContext } from 'react'

//* HOC's
import withUIContext from '../consumerHOC/UIConsumer'
import { withRouter } from 'next/router'

//* Helpers
import { getData, api, asyncFunctionDecorator } from 'helpers'
import _ from 'lodash'

const DataContext = createContext(null)
class DataProvider extends Component {
	//! States
	state = { ...this.props.newData, prevRoute: '' }

	//! Methods
	methods = {
		updatePageData: this.updatePageData.bind(this),
		requestPopup: this.requestPopup.bind(this),
	}

	requestPopup(data) {
		return asyncFunctionDecorator(() => api.post('contact-us', data))
	}

	//! Component Did Mount
	componentDidUpdate(prevProps, prevState, snapshot) {
		const lang = this.props.router.locale || this.props.selectedLang

		if (prevProps.router.asPath !== this.props.router.asPath || prevProps.router.locale !== this.props.router.locale) {
			this.props.toggleLoading(true)
			getData(lang, this.state, this.props.router).then((res) => {
				this.setState({ ...res, prevRoute: prevProps.router.asPath.replace(/\/$/, '') }, () => {
					this.props.toggleLoading(false)
				})
			})
		}
	}

	//! Update Page Data
	updatePageData() {
		const lang = this.props.router.locale || this.props.selectedLang

		return getData(lang, this.state, this.props.router, false, true).then((res) => {
			this.setState(res)
		})
	}

	//! Async Function Catch Decorator
	asyncFunctionDecorator(asyncFunc) {
		return asyncFunc().catch((err) => {
			return Promise.reject(err.response)
		})
	}

	render() {
		return <DataContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</DataContext.Provider>
	}
}

export default withRouter(withUIContext(DataProvider, ['toggleLoading']))
export const DataConsumer = DataContext.Consumer
