import styled from 'styled-components'

const Footer = styled.footer`
	--footerPaddBotTop: var(--sp16x);
	--footerBtnMarg: var(--sp7x);
	--footerLeftWidth: 30.5%;
	--footerRightWidth: 60.5%;
	--footerBottemWidth: 45%;
	--footerMenuTopItemWidth: 32%;
	--footerMenuItemWidth: 39%;

	--menuTitleMarg: var(--sp6x);
	--menuMarg: var(--titleMinMarg);

	background-color: var(--purple);
	padding-top: var(--footerPaddBotTop);
	padding-bottom: var(--footerPaddBotTop);

	.footerTop {
		margin-bottom: var(--btnTopMarg);
		.footerLeft {
			width: var(--footerLeftWidth);
			.btn {
				z-index: 0;
				margin-bottom: var(--footerBtnMarg);
			}
		}

		.footerRight {
			width: var(--footerRightWidth);
			.footerRightTop {
				border-bottom: 1px solid var(--whiteDark);
				.menu-cont {
					margin-bottom: var(--btnTopMarg);
					width: var(--footerMenuTopItemWidth);
					.titleMenu {
						margin-bottom: var(--menuTitleMarg);
					}
					.menu-item {
						margin-bottom: var(--menuMarg);
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			.footerRightBot {
				.footerMenuItem {
					width: var(--footerMenuItemWidth);
					margin-top: var(--btnTopMarg);
					.titleMenu {
						margin-bottom: var(--menuTitleMarg);
					}
					.menu-item {
						display: block;
						margin-bottom: var(--menuMarg);
						line-height: var(--lineHeightS);
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.footerBot {
		.comp-title {
			width: var(--footerBottemWidth);
			&:last-child {
				text-align: right;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--footerPaddBotTop: var(--sp12-5x);
		--footerBtnMarg: var(--sp6x);
		--footerLeftWidth: 36%;
		--footerRightWidth: 53.5%;

		--menuTitleMarg: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--footerPaddBotTop: var(--sp12x);
		--footerBtnMarg: var(--sp5x);
		--footerLeftWidth: 34%;
		--footerRightWidth: 56.8%;

		--menuTitleMarg: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXSMin}) {
		--footerPaddBotTop: var(--sp11x);
		--footerBtnMarg: var(--sp4x);
		--footerLeftWidth: 37%;
		--footerRightWidth: 54%;

		--menuTitleMarg: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSizeMin}) {
		--footerPaddBotTop: var(--sp10x);
		--footerBtnMarg: var(--sp4x);
		--footerLeftWidth: 40%;
		--footerRightWidth: 51%;

		--menuTitleMarg: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--footerPaddBotTop: var(--sp10x);
		--footerBtnMarg: var(--sp4x);
		--footerLeftWidth: 51%;
		--footerRightWidth: 100%;

		--footerMenuTopItemWidth: 30%;
		--footerMenuItemWidth: 39%;

		--menuTitleMarg: var(--sp4x);

		.footerTop {
			margin-bottom: var(--footerPaddBotTop);
			.footerLeft {
				margin-bottom: var(--footerPaddBotTop);
			}
			.footerRight {
				.footerRightTop {
					justify-content: space-between;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--footerBottemWidth: 100%;

		--footerPaddBotTop: var(--sp10x);
		--footerBtnMarg: var(--sp4x);
		--footerLeftWidth: 100%;
		--footerRightWidth: 100%;

		--footerMenuTopItemWidth: 50%;
		--footerMenuItemWidth: 70%;

		--menuTitleMarg: var(--sp4x);

		.footerTop {
			margin-bottom: var(--footerPaddBotTop);
			.footerLeft {
				margin-bottom: var(--footerPaddBotTop);
			}
			.footerRight {
				.footerRightTop {
					justify-content: space-between;
				}
			}
		}

		.footerBot {
			.comp-title {
				&:last-child {
					margin-top: var(--sp3x);
					text-align: left;
				}
			}
		}
	}
`

export default Footer
