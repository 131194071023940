import styled from 'styled-components'

const OurPartnersStyle = styled.div.attrs('')`
	--titleMarg: var(--sp13x);
	--itemsSpace: var(--sp4x);
	--imgWidth: calc(14% - var(--itemsSpace));

	.Title {
		margin-bottom: var(--titleMarg);
	}
	.imageWrapp {
		> div {
			width: var(--imgWidth);
			margin-bottom: var(--itemsSpace);
			.imageCont {
				padding: 19.09%;
				.image {
					object-fit: contain;
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarg: var(--sp9x);
		--imgWidth: calc(15% - var(--itemsSpace));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarg: var(--sp8x);
		--imgWidth: calc(19% - var(--itemsSpace));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarg: var(--sp7x);
		--imgWidth: calc(17% - var(--itemsSpace));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.LaptopSize}) {
		--titleMarg: var(--sp6x);
		--imgWidth: calc(17% - var(--itemsSpace));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSize}) {
		--titleMarg: var(--sp6x);
		--imgWidth: calc(21% - var(--itemsSpace));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMarg: var(--sp5x);
		--imgWidth: 33.333%;
		.imageWrapp {
            width:calc(100% + var(--itemsSpace));
            margin-left:calc(var(--itemsSpace)/-2);
            margin-right:calc(var(--itemsSpace)/-2);
             justify-content: flex-start;
			> div {
				padding: 0 calc(var(--itemsSpace) / 2);
			}
		}
	}
`
export default OurPartnersStyle
