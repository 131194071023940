import styled from 'styled-components'


const BurgerStyle = styled.div`
    
    padding:var(--sp2x) 0 var(--sp2x) var(--sp2x);
    margin:calc(var(--sp2x) * -1) 0 calc(var(--sp2x) * -1) calc(var(--sp2x) * -1);

    .Burger{
        --burgerSize:var(--sp3-5x);
        --burgerheightSize:var(--sp2x);

        cursor: pointer;
        width: var(--burgerSize);
        height: var(--burgerheightSize);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span{
            display:block;
            height:4px;
            width:100%;
            background-color:var(--purple);
            transition:all var(--trTime3);
            transform-origin: 20% center;
        }

        &.active{
            span{
                &:first-child{
                    transform:rotate(45deg);
                }
                &:last-child{
                    transform: rotate(-45deg);
                }
            }
        }
    }

`

export default BurgerStyle