import React, { memo as Memo, useCallback, useMemo } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import { Menu, SocIcon, Button, Icon, Title, RequestPopup } from 'components/common'

//* JSON's
import config from 'helpers/_config'

const MenuComponent = Memo(({ openPopup, ...props }) => {
	//! Routes from Config
	const routes = useMemo(() => config.routes, [])

	const openPopupFun = useCallback(() => {
		openPopup(<RequestPopup />)
	}, [])

	//! Big Menu Object
	const menuObj = useMemo(
		() => ({
			contClass: `menu-cont ${props.winWidth >= 1024 ? 'FlexBox' : 'FlexBoxColumn AlignMiddle'}`,
			itemClass: 'menu-item pM uppercase purple',
			items: [
				{
					name: routes.pricing.name,
					path: routes.pricing.path,
				},
				{
					name: routes.aboutUs.name,
					path: routes.aboutUs.path,
				},
				{
					name: routes.blog.name,
					path: routes.blog.path,
				},
			],
		}),
		[routes, props.winWidth]
	)

	return props.winWidth >= 1024 ? (
		<Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj} />
	) : (
		<div className={`burgerCont FlexBoxColumn SpaceBetween relativeWrap overflowHidden ${props.opened ? 'active' : ''}`}>
			<Menu type={'nav'} onClick={props.onClick} {...menuObj} />
			<Icon className={'icon-weProfitGlobalIsLive green'} />
			<Icon className={'icon-firstPrototype purpleLite'} />
			<Icon className={'icon-rhombus'} />
			<div className='contactInfoWrap FlexBoxColumn AlignMiddle'>
				<Button onClick={openPopupFun} wrapClassName={'purple'}>
					<Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} className={'whiteDark'} />
				</Button>

				<a href='mailto:info@investin.am' target='_blank' rel='noopener noreferrer' className='contactItem purple PolySansNeutralRegular h5'>
					contact@weprofit.global
				</a>
				<a href='mailto:info@investin.am' target='_blank' rel='noopener noreferrer' className='contactItem purple PolySansNeutralRegular h5'>
					+ 49 179 666 7451
				</a>
				<SocIcon
					data={{
						twitter: 'https://twitter.com/weprofit_global',
						facebook: 'https://www.facebook.com/WeProfit.Global/',
						instagram: 'https://www.instagram.com/weprofit.global/',
						linkedin: 'https://www.linkedin.com/company/weprofitglobal/',
					}}
				/>
			</div>
		</div>
	)
})
export default withUIContext(MenuComponent, ['openPopup', 'screenSizes', 'winWidth'])
