import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`

    @font-face {
        font-family: PolySansNeutralRegular;
        src: url("/fonts/PolySans-Neutral.woff2") format("woff2"),
            url("/fonts/PolySans-Neutral.woff") format("woff"),
            url('/fonts/PolySans-Neutral.otf') format('opentype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: PolySansNeutralItalic;
        src: url("/fonts/PolySans-NeutralItalic.woff2") format("woff2"),
            url("/fonts/PolySans-NeutralItalic.woff") format("woff"),
            url('/fonts/PolySans-NeutralItalic.ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: PolySansSlimLightItalic;
        src: url("/fonts/PolySans-SlimItalic.woff2") format("woff2"),
            url("/fonts/PolySans-SlimItalic.woff") format("woff"),
            url('/fonts/PolySans-SlimItalic.otf') format('opentype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: PolySansMedianSemiBoldItalic;
        src: url("/fonts/PolySans-MedianItalic.woff2") format("woff2"),
            url("/fonts/PolySans-MedianItalic.woff") format("woff"),
            url('/fonts/PolySans-MedianItalic.ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: PolySansMedianRegular;
        src: url("/fonts/PolySans-Median.woff2") format("woff2"),
            url("/fonts/PolySans-Median.woff") format("woff"),
            url('/fonts/PolySans-Median.otf') format('opentype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: PolySansBulkyRegular;
        src: url("/fonts/PolySans-Bulky.woff2") format("woff2"),
            url("/fonts/PolySans-Bulky.woff") format("woff"),
            url('/fonts/PolySans-Bulky.ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    /* Typography */

    * {
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
    }

    * {
        -webkit-font-smoothing: antialiased;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        box-shadow: inset 1000px 1000px #fff;
    }

    input:focus::placeholder {
        color: transparent;
    }

    input {
        outline: none;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px #fff !important;
    }

    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 2px solid var(--black);
        -webkit-text-fill-color: var(--purpleLite);
        color: var(--white);
        background-color: transparent;
        -webkit-box-shadow: 0 0 0 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    }

    @-webkit-keyframes autofill {
        to {
            background: transparent;
        }
    }


    //Text capitalize

    .uppercase {
        text-transform: uppercase;
    }

    .lowercase {
        text-transform: lowercase;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .underline {
        text-decoration: underline;
    }

    .lineThrough{
        text-decoration: line-through;
    }

    .textAlignCenter {
        text-align:center;
    }
    
    .inlineBlock {
        display:inline-block;
    }

    //!Fonts

    .PolySansNeutralRegular {
        font-family: PolySansNeutralRegular;
    }

    .PolySansNeutralItalic {
        font-family: PolySansNeutralItalic;
    }

    .PolySansSlimLightItalic {
        font-family: PolySansSlimLightItalic;
    }

    .PolySansMedianSemiBoldItalic {
        font-family: PolySansMedianSemiBoldItalic;
    }

    .PolySansMedianRegular {
        font-family: PolySansMedianRegular;
    }

    .PolySansBulkyRegular {
        font-family: PolySansBulkyRegular;
    }

    //!Font Sizes

    .h1,
    h1 {
        font-size: var(--h1);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
        
    }

    .h2,
    h2 {
        font-size: var(--h2);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h3,
    h3 {
        font-size: var(--h3);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h4,
    h4 {
        font-size: var(--h4);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h5,
    h5 {
        font-size: var(--h5);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h6,
    h6 {
        font-size: var(--h6);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .pL,
    p {
        font-size: var(--pL);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .pM {
        font-size: var(--pM);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .pS {
        font-size: var(--pS);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }
`

export default Typography
