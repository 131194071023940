import React, { Component, createContext } from 'react'

//* Hooks
import { withRouter } from 'next/router'

//* Helpers
import { routerToPathAndQuery } from 'helpers'

//* Global Variables
const errorsInitialState = {
    formError: {
        error: false,
        names: [],
        msg: {},
    },
    systemError: {
        error: false,
        msg: '',
    },
}

const UIContext = createContext(null)

class UIProvider extends Component {
    //! States
    state = {
        loading: false,
        preloader: true,
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            screen3XL: '',
            screen2XL: '',
            screenXL: '',
            screenL: '',
            screenM: '',
            screenS: '',
            screenXS: '',
        },
        colors: {},
        ...errorsInitialState,
        popupIsOpen: false,
        popupComponent: null,
    }

    //! Methods
    methods = {
        openPopup: this.openPopup.bind(this),
        openRegisterPopup: this.openRegisterPopup.bind(this),
        closePopup: this.closePopup.bind(this),
        toggleLoading: this.toggleLoading.bind(this),
        togglePopup: this.togglePopup.bind(this),
        disableDomScroll: this.disableDomScroll.bind(this),
        enableDomScroll: this.enableDomScroll.bind(this),
    }

    popup = ['register']

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    //! Get Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement)

        const screenSizes = {
            screen3XL: parseInt(root.getPropertyValue('--screen3XL')),
            screen2XL: parseInt(root.getPropertyValue('--screen2XL')),
            screenXL: parseInt(root.getPropertyValue('--screenXL')),
            screenL: parseInt(root.getPropertyValue('--screenL')),
            screenM: parseInt(root.getPropertyValue('--screenM')),
            screenS: parseInt(root.getPropertyValue('--screenS')),
            screenXS: parseInt(root.getPropertyValue('--screenXS')),
        }

        const colors = {
            black: root.getPropertyValue('--black').trim(),
            white: root.getPropertyValue('--white').trim(),
            color1: root.getPropertyValue('--color1').trim(),
            color2: root.getPropertyValue('--color2').trim(),
        }

        this.setState({ screenSizes, colors })
    }

    //! Open Popup
    openPopup(popupComponent = null) {
        if (typeof popupComponent === 'string' && this.popup.includes(popupComponent) && this.props.router.query?.c !== popupComponent) {
            const { pathname, query } = routerToPathAndQuery(this.props.router, 'c', popupComponent)
            this.props.router.push({ pathname, query }, undefined, { shallow: true })
        }

        this.disableDomScroll()
        this.setState({
            popupIsOpen: true,
            popupComponent,
        })
    }

    //! Open Register Popup
    openRegisterPopup() {
        this.openPopup(this.popup[0])
    }

    //! Close Popup
    closePopup(scroll = false) {
        const { pathname, query } = routerToPathAndQuery(this.props.router, 'c')
        this.props.router.push({ pathname, query }, undefined, { scroll: false })

        this.setState({
            popupIsOpen: false,
            popupComponent: null,
        }, () => {
            this.enableDomScroll()
        })
    }

    //! Toggle Popup
    togglePopup() {
        this.setState({
            popupIsOpen: !this.state.popupIsOpen,
        })
    }

    //! Toggle Loading
    toggleLoading(x) {
        this.setState({
            loading: x,
        })
    }

    //! Query Popups Checking and Opening
    queryPopupsCheckAndOpen() {
        const popupQuery = this.props.router.query.c

        if (popupQuery && this.popup.includes(popupQuery) && this.state.popupComponent !== popupQuery) {
            this.openPopup(popupQuery)
        }
    }

    //! Query Popups Checking and Closing
    queryPopupsCheckAndClose(prevProps) {
        const popupQuery = this.props.router.query?.c

        if (!popupQuery && this.state.popupIsOpen && this.popup.includes(this.state.popupComponent)) {
            this.setState({
                popupIsOpen: false,
                popupComponent: null,
            })
        }
    }

    getLoaderEnd() {
        setTimeout(() => {
            this.setState({ preloader: false })
        }, 3200)
    }

    //! Disable DOM Scroll
    disableDomScroll() {
        document.querySelector('body').classList.add('modal-open')
    }

    //! Enable DOM Scroll
    enableDomScroll() {
        document.querySelector('body').classList.remove('modal-open')
    }

    //! Component Did Mount
    componentDidMount() {
        window.addEventListener('resize', this.screenResize)

        this.getScreenSizes()
        this.screenResize()

        this.getLoaderEnd()

        this.queryPopupsCheckAndOpen()
        this.props.router.events.on('routeChangeComplete', this.queryPopupsCheckAndClose.bind(this))
    }

    //! Component Did Update
    componentDidUpdate(prevProps, prevState) {
        this.queryPopupsCheckAndOpen(prevProps)
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize)
        document.removeEventListener('keydown', this.escKeydown, false)
        this.props.router.events.off('routeChangeComplete', this.queryPopupsCheckAndClose.bind(this))
    }

    render() {
        return <UIContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</UIContext.Provider>
    }
}

export default withRouter(UIProvider)
export const UIConsumer = UIContext.Consumer
