import React, { memo as Memo, useMemo } from 'react'

//* HOCs
import { withUIContext } from 'context'

//* Components
import { Icon, RequestPopup } from 'components/common'

//* Style
import PopupStyle from './style'

const Popup = Memo(({ closePopup, popupIsOpen, popupComponent, ...props }) => {
	const components = useMemo(
		() => ({
			register: RequestPopup,
		}),
		[]
	)

	const Child = useMemo(() => typeof popupComponent === 'string' && components[popupComponent] && components[popupComponent], [popupComponent])

	return popupIsOpen ? (
		<PopupStyle className='FlexBox AlignCenter'>
			<div className='popup-content-container FlexBox AlignMiddle'>
				<div className='popup-background' onClick={closePopup} />
				<div className='popup-content-wrap relativeWrap'>
					<div className='close FlexBox AlignMiddle AlignCenter cursorPointer' onClick={closePopup}>
						<Icon className='icon-close' />
					</div>
					{typeof popupComponent === 'string' ? <Child /> : popupComponent}
				</div>
			</div>
		</PopupStyle>
	) : (
		''
	)
})

export default withUIContext(Popup, ['closePopup', 'popupIsOpen', 'popupComponent'])
