import { memo as Memo, useMemo } from 'react'

//* HOC's
import { useRouter } from 'next/router'

//* Components
import { NextSeo } from 'next-seo'

//* Styles
import PageStyle from './style'

const Page = Memo(({ meta, ...props }) => {
    //! Router
    const router = useRouter()

    //! URL
    const url = useMemo(() => process.env.host + (router.locale !== 'en' ? '/' + router.locale : '') + router.asPath, [router])

    //! Descritpion
    const description = useMemo(() => meta?.description || 'The B2B marketplace for software development. Hire curated and vetted development companies and bid on world class projects.', [meta?.description])

    //! Title
    const title = useMemo(() => meta?.title || 'WeProfit - The software development marketplace', [meta?.title])

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                canonical={url}
                type='website'
                additionalMetaTags={[
                    {
                        name: 'keywords',
                        content: meta?.keywords,
                    },
                ]}
                openGraph={{
                    url: url,
                    title: title,
                    description: description,
                    images: [{ url: meta?.image || process.env.host + '/images/map.png', alt: title }],
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />

            <PageStyle className={props.className}>{props.children}</PageStyle>
        </>
    )
})

export default Page
