import React, { memo as Memo, useMemo } from 'react'

//* HOCs
import { withDataContext, withLanguageContext } from 'context'

//*component
import { Icon } from 'components/common'

//style
import ShareStyle from './style'

const SocIcon = Memo(({ global, selectedLang, ...props }) => {
    // const data = useMemo(() => global?.[selectedLang], [global, selectedLang])

    return props.data ? (
        <ShareStyle className='socIconWrapp FlexBox alignMiddle purple'>
            <a href={props.data.twitter} className='socIconItem FlexBox alignMiddle purple' aria-label="twitter" target='_blank' rel='noopener noreferrer'>
                <Icon className={'icon-twitter'} />
            </a>
            <a href={props.data.facebook} className='socIconItem FlexBox alignMiddle purple' aria-label="facebook" target='_blank' rel='noopener noreferrer'>
                <Icon className={'icon-facebook'} />
            </a>
            <a href={props.data.instagram} className='socIconItem FlexBox alignMiddle purple' aria-label="instagram" target='_blank' rel='noopener noreferrer'>
                <Icon className={'icon-instagram'} />
            </a>
            <a href={props.data.linkedin} className='socIconItem FlexBox alignMiddle purple' aria-label="linkedin" target='_blank' rel='noopener noreferrer'>
                <Icon className={'icon-linkedin'} />
            </a>
        </ShareStyle>
    ) : null
})

export default withLanguageContext(withDataContext(SocIcon, ['global']), ['selectedLang'])