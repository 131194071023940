import React, { memo as Memo, useCallback, useEffect, useState } from 'react'

//* HOCs
import { withLanguageContext, withUIContext } from 'context'
import { useRouter } from 'next/router'

//*Component
import Logo from './HeaderComponent/Logo'
import MenuComponent from './HeaderComponent/MenuComponent'
import Burger from './HeaderComponent/Burger'
import { Button, LoginBtn, Title, RequestPopup } from 'components/common'

import Header from './style'

const AppHeader = Memo(({ openPopup, ...props }) => {
	//! States
	const [checked, setChecked] = useState(false)
	const [opened, setOpened] = useState(false)

	//! Router
	const router = useRouter()

	const openPopupFun = useCallback(() => {
		openPopup(<RequestPopup />)
	}, [])

	useEffect(() => {
		if (checked && !opened) {
			setOpened(true)
			document.body.classList.add('modal-open')
		} else if (!checked && opened) {
			setOpened(false)
			document.body.classList.remove('modal-open')
		}
	}, [checked, opened, router, router.asPath, props.winWidth])

	useEffect(() => {
		setChecked(false)
	}, [router])

	const handleClick = () => {
		setChecked(!checked)
	}

	return (
		<Header className='FlexBox AlignMiddle SpaceBetween'>
			<div className='headerLeft AlignMiddle SpaceBetween'>
				<Logo className={'headerLogo'} start={props.preloader} />
				<MenuComponent opened={opened} />
			</div>
			<div className='headerRight FlexBox AlignMiddle JustifyEnd'>
				<LoginBtn />

				{props.winWidth < 1024 ? (
					<Burger checked={checked} onClick={handleClick} />
				) : (
					<Button onClick={openPopupFun} wrapClassName={'purple'}>
						<Title type={'btn'} text={['requestAn', 'onboarding']} fontSize={'pM'} className={'whiteDark'} />
					</Button>
				)}
			</div>
		</Header>
	)
})

export default withLanguageContext(withUIContext(AppHeader, ['screenSizes', 'winWidth', 'preloader', 'openPopup'], ['translate', 'selectedLang']))
