import styled from 'styled-components'

const Header = styled.header`
	--padingTopBot: var(--sp7x);
	--padingLeeftRight: var(--sp13x);
	--menuMarg: var(--sp21x);
	--loginMarg: var(--sp11x);

	padding: var(--padingTopBot) var(--padingLeeftRight);
	position: relative;
	z-index: 11;

	.headerLeft {
		display: inline-flex;
	}

	.menu-item {
		margin-right: var(--menuMarg);
		&:last-child {
			margin-right: 0;
		}
	}

	.Login {
		margin-right: var(--loginMarg);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--padingTopBot: var(--sp5x);
		--padingLeeftRight: var(--sp10x);
		--menuMarg: var(--sp15x);
		--loginMarg: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--padingTopBot: var(--sp4x);
		--padingLeeftRight: var(--sp7x);
		--menuMarg: var(--sp11x);
		--loginMarg: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXSMin}) {
		--padingTopBot: var(--sp4x);
		--padingLeeftRight: var(--sp6x);
		--menuMarg: var(--sp9x);
		--loginMarg: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSizeMin}) {
		--padingTopBot: var(--sp4x);
		--padingLeeftRight: var(--sp7x);
		--menuMarg: var(--sp6x);
		--loginMarg: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--padingTopBot: var(--sp3x);
		--padingLeeftRight: var(--sp4x);
		--menuMarg: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--loginMarg: var(--sp3x);

		.burgerCont {
			border-top: 1px solid var(--greyLight);
			position: fixed;
			top: calc(var(--padingTopBot) * 2 + var(--pL));
			bottom: 0;
			left: 0;
			right: 0;
			visibility: hidden;
			pointer-events: none;
			opacity: 0;
			background-color: transparent;
			transition: all var(--trTime2) ease;
			z-index: 0;
			padding: calc(var(--padingTopBot) * 2) var(--padingLeeftRight) var(--padingTopBot);
			padding-top: var(--sp21x);
            overflow: scroll;

			::-webkit-scrollbar {
				display: none;
			}

			&.active {
				visibility: visible;
				pointer-events: all;
				opacity: 1;
				z-index: 12;
				background-color: var(--whiteDark);
                overflow-x: hidden;
				.menu-cont {
					.menu-item {
						transform: scale(1);
					}
				}

				.icon-weProfitGlobalIsLive {
					transform: translate(-42%, -15%);
				}
				.icon-firstPrototype {
					transform: translate(-42%, 0);
				}
				.icon-rhombus {
					transform: translate(40%, 0%);
				}

				.contactInfoWrap {
					a {
						transform: scale(1);
					}
				}
			}
			.menu-cont {
				flex: 1 0 auto;
				.menu-item {
					margin-right: 0;
					font-size: var(--h3);
					margin-bottom: var(--menuMarg);
					transform: scale(0);
					transition: transform var(--trTime4) cubic-bezier(0, 1.48, 0.81, 1.52);
				}
			}

			.contactInfoWrap {
				margin-bottom: var(--sp5x);
				a {
					transform: scale(0);
					transition: transform var(--trTime3) cubic-bezier(0.01, 1.38, 0.69, 1.22);
				}
				.btn {
					margin-bottom: var(--menuMarg);
				}
				.contactItem {
					margin-bottom: var(--sp4x);
				}
			}

			> i {
				position: absolute;
				z-index: -1;
				transition: transform var(--trTime4) cubic-bezier(0, 0.74, 0, 1.16);
			}

			.icon-weProfitGlobalIsLive {
				font-size: var(--sp32x);
				top: 0;
				left: 0;
				transform: translate(-100%, -60%);
			}
			.icon-firstPrototype {
				font-size: var(--sp27x);
				top: 40%;
				left: 0;
				transform: translate(-100%, -60%);
			}
			.icon-rhombus {
				font-size: var(--sp32x);
				top: 50%;
				right: 0;
				color: #fd9539;
				transform: translate(120%, 20%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--padingTopBot: var(--sp3x);
		--padingLeeftRight: var(--sp2x);
		--menuMarg: var(--sp7x);

		.burgerCont {
			top: calc(var(--padingTopBot) * 2 + var(--pM));
			padding-top: var(--sp17x);

			.contactInfoWrap {
				margin-bottom: var(--sp4x);
				.contactItem {
					margin-bottom: var(--sp3x);
				}
			}
			.menu-cont {
				.menu-item {
					font-size: var(--h2);
				}
			}

			.icon-weProfitGlobalIsLive {
				font-size: var(--sp22x);
			}
			.icon-firstPrototype {
				font-size: var(--sp20x);
			}
			.icon-rhombus {
				font-size: var(--sp21x);
			}
		}
	}
`

export default Header
