import { createGlobalStyle } from 'styled-components'

const Variables = createGlobalStyle`

:root {
    //!Colors
    --white:${(props) => props.theme.colors.white};
    --whiteDark: ${(props) => props.theme.colors.whiteDark};

    --purple: ${(props) => props.theme.colors.purple};
    --purpleLite: ${(props) => props.theme.colors.purpleLite};
    --purpleMediumLite: ${(props) => props.theme.colors.purpleMediumLite};
    --purpleUltraLite:${(props) => props.theme.colors.purpleUltraLite};

    --grey: ${(props) => props.theme.colors.grey};
    --greyUltraDark: ${(props) => props.theme.colors.greyUltraDark};
    --greyUltraLight: ${(props) => props.theme.colors.greyUltraLight};
    --greyLight: ${(props) => props.theme.colors.greyLight};
    --backgroundGrey: ${(props) => props.theme.colors.backgroundGrey};

    --green: ${(props) => props.theme.colors.green};
    --greenLight: ${(props) => props.theme.colors.greenLight};
    --orange: ${(props) => props.theme.colors.orange};
    --orangeDeep: ${(props) => props.theme.colors.orangeDeep};
    
    //error color
    --errColor: ${(props) => props.theme.colors.errColor};

   //! Spacing
    --spSize: 8px;
    
    --sp0-5x: calc(var(--spSize) * 0.5);
    --sp0-75x: calc(var(--spSize) * 0.75);
    --sp1x: calc(var(--spSize) * 1);
    --sp1-1x: calc(var(--spSize) * 1.1);
    --sp1-25x: calc(var(--spSize) * 1.25);
    --sp1-3x: calc(var(--spSize) * 1.3);
    --sp1-5x: calc(var(--spSize) * 1.5);
    --sp1-6x: calc(var(--spSize) * 1.7);
    --sp1-75x: calc(var(--spSize) * 1.75);
    --sp2x: calc(var(--spSize) * 2);
    --sp2-25x: calc(var(--spSize) * 2.25);
    --sp2-5x: calc(var(--spSize) * 2.5);
    --sp2-8x: calc(var(--spSize) * 2.8);
    --sp3x: calc(var(--spSize) * 3);
    --sp3-5x: calc(var(--spSize) * 3.5);
    --sp4x: calc(var(--spSize) * 4);
    --sp4-5x: calc(var(--spSize) * 4.5);
    --sp5x: calc(var(--spSize) * 5);
    --sp5-5x: calc(var(--spSize) * 5.5);
    --sp6x: calc(var(--spSize) * 6);
    --sp6-5x: calc(var(--spSize) * 6.5);
    --sp7x: calc(var(--spSize) * 7);
    --sp7-5x: calc(var(--spSize) * 7.5);
    --sp8x: calc(var(--spSize) * 8);
    --sp8-5x: calc(var(--spSize) * 8.5);
    --sp9x: calc(var(--spSize) * 9);
    --sp9-5x: calc(var(--spSize) * 9.5);
    --sp10x: calc(var(--spSize) * 10);
    --sp11x: calc(var(--spSize) * 11);
    --sp11-5x: calc(var(--spSize) * 11.5);
    --sp12x: calc(var(--spSize) * 12);
    --sp12-5x: calc(var(--spSize) * 12.5);
    --sp13x: calc(var(--spSize) * 13);
    --sp14x: calc(var(--spSize) * 14);
    --sp14-5x: calc(var(--spSize) * 14.5);
    --sp15x: calc(var(--spSize) * 15);
    --sp15-5x: calc(var(--spSize) * 15.5);
    --sp16x: calc(var(--spSize) * 16);
    --sp16-5x: calc(var(--spSize) * 16.5);
    --sp17x: calc(var(--spSize) * 17);
    --sp17-5x: calc(var(--spSize) * 17.5);
    --sp18x: calc(var(--spSize) * 18);
    --sp18-5x: calc(var(--spSize) * 18.5);
    --sp19x: calc(var(--spSize) * 19);
    --sp19-5x: calc(var(--spSize) * 19.5);
    --sp20x: calc(var(--spSize) * 20);
    --sp20-5x: calc(var(--spSize) * 20.5);
    --sp21x: calc(var(--spSize) * 21);
    --sp22x: calc(var(--spSize) * 22);
    --sp22-5x: calc(var(--spSize) * 22.5);
    --sp23x: calc(var(--spSize) * 23);
    --sp23-5x: calc(var(--spSize) * 23.5);
    --sp24x: calc(var(--spSize) * 24);
    --sp25x: calc(var(--spSize) * 25);
    --sp26x: calc(var(--spSize) * 26);
    --sp26-5x: calc(var(--spSize) * 26.5);
    --sp27x: calc(var(--spSize) * 27);
    --sp28x: calc(var(--spSize) * 28);
    --sp29x: calc(var(--spSize) * 29);
    --sp30x: calc(var(--spSize) * 30);
    --sp30-5x: calc(var(--spSize) * 30.5);
    --sp31x: calc(var(--spSize) * 31);
    --sp32x: calc(var(--spSize) * 32);
    --sp33x: calc(var(--spSize) * 33);
    --sp34x: calc(var(--spSize) * 34);
    --sp35x: calc(var(--spSize) * 35);
    --sp36x: calc(var(--spSize) * 36);
    --sp37x: calc(var(--spSize) * 37);
    --sp38x: calc(var(--spSize) * 38);
    --sp39x: calc(var(--spSize) * 39);
    --sp40x: calc(var(--spSize) * 40);
    --sp41x: calc(var(--spSize) * 41);
    --sp41-5x: calc(var(--spSize) * 41.5);
    --sp42x: calc(var(--spSize) * 42);
    --sp42-5x: calc(var(--spSize) * 42.5);
    --sp43x: calc(var(--spSize) * 43);
    --sp44x: calc(var(--spSize) * 44);
    --sp45x: calc(var(--spSize) * 45);
    --sp46x: calc(var(--spSize) * 46);
    --sp47x: calc(var(--spSize) * 47);
    --sp48x: calc(var(--spSize) * 48);
    --sp49x: calc(var(--spSize) * 49);
    --sp50x: calc(var(--spSize) * 50);
    --sp52x: calc(var(--spSize) * 52);
    --sp53x: calc(var(--spSize) * 53);
    --sp53-5x: calc(var(--spSize) * 53.5);
    --sp54x: calc(var(--spSize) * 54);
    --sp55x: calc(var(--spSize) * 55);
    --sp55-5x: calc(var(--spSize) * 55.5);
    --sp56x: calc(var(--spSize) * 56);
    --sp57x: calc(var(--spSize) * 57);
    --sp57-5x: calc(var(--spSize) * 57.5);
    --sp58x: calc(var(--spSize) * 58);
    --sp59x: calc(var(--spSize) * 59);
    --sp60x: calc(var(--spSize) * 60);
    --sp61x: calc(var(--spSize) * 61);
    --sp62x: calc(var(--spSize) * 62);
    --sp63x: calc(var(--spSize) * 63);
    --sp64x: calc(var(--spSize) * 64);
    --sp66x: calc(var(--spSize) * 66);
    --sp67x: calc(var(--spSize) * 67);
    --sp68x: calc(var(--spSize) * 68);
    --sp68-5x: calc(var(--spSize) * 68.5);
    --sp69x: calc(var(--spSize) * 69);
    --sp70x: calc(var(--spSize) * 70);
    --sp72x: calc(var(--spSize) * 72);
    --sp72-5x: calc(var(--spSize) * 72.5);
    --sp73x: calc(var(--spSize) * 73);
    --sp75x: calc(var(--spSize) * 75);
    --sp77x: calc(var(--spSize) * 77);
    --sp78x: calc(var(--spSize) * 78);
    --sp80x: calc(var(--spSize) * 80);
    --sp81x: calc(var(--spSize) * 81);
    --sp81-5x: calc(var(--spSize) * 81.5);
    --sp82x: calc(var(--spSize) * 82);
    --sp83x: calc(var(--spSize) * 83);
    --sp84x: calc(var(--spSize) * 84);
    --sp85x: calc(var(--spSize) * 85);
    --sp85-5x: calc(var(--spSize) * 85.5);
    --sp88x: calc(var(--spSize) * 88);
    --sp89x: calc(var(--spSize) * 89);
    --sp90x: calc(var(--spSize) * 90);
    --sp91x: calc(var(--spSize) * 91);
    --sp93x: calc(var(--spSize) * 93);
    --sp94x: calc(var(--spSize) * 94);
    --sp96x: calc(var(--spSize) * 96);
    --sp97x: calc(var(--spSize) * 97);
    --sp98x: calc(var(--spSize) * 98);
    --sp99x: calc(var(--spSize) * 99);
    --sp100x: calc(var(--spSize) * 100);
    --sp101x: calc(var(--spSize) * 101);
    --sp102x: calc(var(--spSize) * 102);
    --sp103x: calc(var(--spSize) * 103);
    --sp106x: calc(var(--spSize) * 106);
    --sp108x: calc(var(--spSize) * 108);
    --sp110x: calc(var(--spSize) * 110);
    --sp112x: calc(var(--spSize) * 112);
    --sp115x: calc(var(--spSize) * 115);
    --sp117x: calc(var(--spSize) * 117);
    --sp118x: calc(var(--spSize) * 118);
    --sp120x: calc(var(--spSize) * 120);
    --sp125x: calc(var(--spSize) * 125);
    --sp128x: calc(var(--spSize) * 128);
    --sp129x: calc(var(--spSize) * 129);
    --sp123x: calc(var(--spSize) * 123);
    --sp132x: calc(var(--spSize) * 132);
    --sp136x: calc(var(--spSize) * 136);
    --sp141x: calc(var(--spSize) * 141);
    --sp142x: calc(var(--spSize) * 142);
    --sp148x: calc(var(--spSize) * 148);
    --sp149x: calc(var(--spSize) * 149);
    --sp152x: calc(var(--spSize) * 152);
    --sp166x: calc(var(--spSize) * 166);
    --sp175x: calc(var(--spSize) * 175);
    --sp200x: calc(var(--spSize) * 200);

    //transition
    --trTime: 0.3s;
    --trTime2: 0.5s;
    --trTime3: 0.7s;
    --trTime4: 1s;

    //line heights
    --lineHeightS: 1.4;
    --lineHeightXS: 1.2;

    //border
    --borderSm:calc(var(--spSize)/2);
    --borderMd:var(--spSize);
    --borderLg:100px;

    --maxWidth: 100%;
}

@media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
    :root {
        --h1: 112px;
        --h2: 88px;
        --h3: 80px;
        --h4: 64px;
        --h5: 56px;

        --h6: 40px;
        --pL:32px;
        --pM:24px;
        --pS:20px;

        //grid 
        --containerPadding: var(--sp25x);
        --containerMarginTop:var(--sp22x);

        --containerMarginBottom:var(--sp23x);
    }
}


@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {

    :root {
       
        --h1: 88px;
        --h2: 80px;
        --h3: 72px;
        --h4: 56px;
        --h5: 48px;

        --h6: 32px;
        --pL:24px;
        --pM:20px;
        --pS:16px;

        //!grid max width
        --containerPadding: var(--sp19x);

        //container 
        --containerMarginTop:var(--sp16x);
        --containerMarginBottom:var(--sp20x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeS}) {
    :root {
        --h1: 64px;
        --h2: 56px;
        --h3: 48px;
        --h4: 48px;
        --h5: 32px;

        --h6: 24px;
        --pL:20px;
        --pM:16px;
        --pS:16px;

        //!grid max width
        --containerPadding: var(--sp14x);
        --containerMarginTop:var(--sp13x);
        --containerMarginBottom:var(--sp18x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
    :root {
        --h1: 56px;
        --h2: 56px;
        --h3: 48px;
        --h4: 48px;
        --h5: 32px;

        --h6: 24px;
        --pL:20px;
        --pM:16px;
        --pS:14px;

        //!grid max width
        --containerPadding: var(--sp10x);
        --containerMarginTop:var(--sp12x);
        --containerMarginBottom:var(--sp15x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSize}) {
    :root {
        --h1: 48px;
        --h2: 48px;
        --h3: 40px;
        --h4: 40px;
        --h5: 24px;

        --h6: 20px;
        --pL:16px;
        --pM:14px;
        --pS:14px;

        //!grid max width
        --containerPadding: var(--sp9x);
        --containerMarginTop:var(--sp12x);
        --containerMarginBottom:var(--sp13x);

    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSize}) {
    :root {
        //!grid max width
        --containerPadding: var(--sp4x);

    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
    :root {
        --h1: 32px;
        --h2: 32px;
        --h3: 24px;
        --h4: 24px;
        --h5: 16px;

        --h6: 20px;
        --pL:16px;
        --pM:14px;
        --pS:14px;
        
        //!grid max width
        --containerPadding: var(--sp2x);
        --containerMarginTop:var(--sp10x);
        --containerMarginBottom:var(--sp10x);
    }
}
`

export default Variables
