export default {
    colors: {
        //primary colors

        white: '#fff',
        whiteDark: '#F8F8F8',

        purple: '#532980',
        purpleLite: '#B777FA',
        purpleMediumLite: '#E3D6F1',
        purpleUltraLite: '#F4EEF9',

        grey: '#7c7c7c',
        greyUltraDark: '#52475e',
        greyUltraLight: '#a2a2a2',
        greyLight: '#cecece',
        backgroundGrey: '#EFEFEF',

        green: '#02DB9C',
        greenLight: '#C5EBDE',
        orange: '#F4CAA9',
        orangeDeep: '#FC8845',

        //error color
        errColor: '#FF0000',
    },

    mediaQuery: {
        DesktopSizeL: '2560px',
        DesktopSizeM: '1920px',
        DesktopSizeS: '1440px',
        DesktopSizeXS: '1280px',
        LaptopSize: '1024px',
        TabletSize: '768px',

        DesktopSizeLMin: '2559px',
        DesktopSizeMMin: '1919px',
        DesktopSizeSMin: '1439px',
        DesktopSizeXSMin: '1279px',
        LaptopSizeMin: '1023px',
        TabletSizeMin: '767px',
    },
}
